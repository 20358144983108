/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { useEffect, useState } from "react"
import styled from "styled-components"
import theme, { rem } from "@src/theme"
import CarrotDown from "@components/assets/svg/carrot-down.svg"
import { Themed } from '@theme-ui/mdx'


const Dropdown = ({ value, options, onChange }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(0)

  useEffect(() => {
    const newValue = options.findIndex(option => option.value === value)
    setSelectedOption(newValue >= 0 ? newValue : 0)
  }, [value])

  const optionsList = options

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen)
  }

  const setSelectedThenCloseDropdown = index => {
    onChange(options[index].value)
    setSelectedOption(index)
    setIsOptionsOpen(false)
  }

  const handleKeyDown = index => e => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault()
        setSelectedThenCloseDropdown(index)
        break
      default:
        break
    }
  }

  const handleListKeyDown = e => {
    switch (e.key) {
      case "Escape":
        e.preventDefault()
        setIsOptionsOpen(false)
        break
      case "ArrowUp":
        e.preventDefault()
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        )
        break
      case "ArrowDown":
        e.preventDefault()
        setSelectedOption(
          selectedOption == optionsList.length - 1 ? 0 : selectedOption + 1
        )
        break
      default:
        break
    }
  }

  return (
    <Wrapper>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Button
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          className={isOptionsOpen ? "expanded" : ""}
          onClick={toggleOptions}
          onKeyDown={handleListKeyDown}
        >
          {optionsList[selectedOption].label}
          <CarrotDown
            sx={{
              transform: isOptionsOpen
                ? "translate(5px, -4px) rotate(180deg)"
                : "translate(5px, -4px)",
              color: "currentColor",
              fill: "currentColor",
              transition: "all 0.3s ease-in-out",
            }}
          />
        </Button>
        <List
          className={`options ${isOptionsOpen ? "show" : ""}`}
          role="listbox"
          aria-activedescendant={optionsList[selectedOption]}
          tabIndex={-1}
          onKeyDown={handleListKeyDown}
          sx={{
            display: isOptionsOpen ? "block" : "none",
          }}
        >
          {optionsList.map((option, index) =>
            selectedOption !== index ? (
              <Item
                id={option}
                role="option"
                tabIndex={0}
                onKeyDown={handleKeyDown(index)}
                onClick={() => {
                  setSelectedThenCloseDropdown(index)
                }}
              >
                {option.label}
              </Item>
            ) : null
          )}
        </List>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  position: relative;
  min-width: 150px;
`

const Button = styled.button`
  color: ${theme.colors.darkPurple};
  margin: ${rem(8)} ${rem(24)};

  font-family: ${theme.fonts.heading};
  font-weight: ${theme.typography.heading.fontWeight};
  font-size: ${rem(24)};
  /* text-transform: uppercase; */
  text-align: center;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.purple};
  }
`

const List = styled.ul`
  width: 100%;
  padding: 4px 0;
  margin-top: -4px;

  list-style: none;
  text-align: center;
  /* text-transform: uppercase; */
  color: ${theme.colors.purple};
`

const Item = styled.li`
  padding: 0 ${rem(24)} ${rem(8)} ${rem(24)};
  text-decoration: underline;

  font-size: ${rem(24)};
  font-family: ${theme.fonts.heading};
  font-weight: ${theme.typography.heading.fontWeight};

  &:focus,
  &:hover {
    cursor: pointer;
    color: ${theme.colors.darkPurple};
  }
`

export default Dropdown
