/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"
import * as _ from "lodash"
import theme, { rem } from "@src/theme"
import ResourceLink from "@components/Link"
import Download from "@components/assets/svg/download.svg"
import ArrowRight from "@components/assets/svg/arrow-right.svg"
const ResourceCard = ({ data, linkPrefix = "/resources" }) => {
  const { pdf, link } = data.node.frontmatter

  return (
    <Outer
      to={`${linkPrefix}/${data.slug}`}
      sx={{
        display: "block",
        textDecoration: "none",
        height: "100%",
        bg: "white",
      }}
    >
      <figure
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: 0,
          color: "currentColor",
          height: "100%",
          position: "relative",
        }}
      >
        <img
          src={data.node.frontmatter.image}
          alt=""
          sx={{
            height: rem(200),
            objectFit: "cover",
            width: "100%",
            zIndex: -2,
          }}
        />
        <div
          sx={{
            p: rem(30),
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div>
            <figcaption
              sx={{
                variant: "typography.heading",
                fontSize: [rem(22)],
                lineHeight: 1.4,
                color: "currentColor",
              }}
            >
              {data.node.frontmatter.title}
            </figcaption>
            <p
              sx={{
                fontSize: rem(16),
              }}
            >
              {data.preview}
            </p>
          </div>
          <div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {(pdf || link) && (
              <React.Fragment>
                <ResourceLink
                  external
                  link={pdf || link}
                  style={{
                    fontSize: [rem(22)],
                  }}
                >
                  {pdf ? "Download" : "View resource"}
                </ResourceLink>
                {pdf && (
                  <span
                    sx={{
                      display: "block",
                      marginLeft: "12px",
                      transform: "translateY(-1px)",
                    }}
                  >
                    <Download />
                  </span>
                )}
                {!pdf && (
                  <span
                    sx={{
                      display: "block",
                      marginLeft: "12px",
                      transform: "translateY(0px)",
                      width: "15px",
                    }}
                  >
                    <ArrowRight width="100%" height="100%" />
                  </span>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </figure>
    </Outer>
  )
}

export default ResourceCard

const Outer = styled(Link)`
  cursor: pointer;
  position: relative;
  z-index: 1;
  color: ${theme.colors.darkPurple};
  a {
    color: ${theme.colors.darkPurple};
  }
  &:hover,
  &:active,
  &:focus {
    background-color: ${theme.colors.lightGrey};
    transition: background-color 300ms ease, color 300ms ease;
  }
`
