/** @jsx jsx */
import { jsx } from "theme-ui"
import { Fragment } from "react"
import Pill from "@components/Pill"
import Container from "@components/Container"
import BannerBlock from "@components/BannerBlock"

const PillList = ({ options, filters, onPillClick }) => (
  <Container
    sx={{
      mb: 5,
    }}
  >
    <ul
      sx={{
        display: "flex",
        listStyle: "none",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {options.length
        ? options
            .filter(({ hidden }) => !hidden)
            .map(({ label }) => (
              <li sx={{ mr: 2, mb: 1 }} key={label}>
                <Pill
                  key={label}
                  active={filters && filters.indexOf(label) !== -1}
                  onClick={() => onPillClick(label)}
                  style={{
                    "&:hover": {
                      bg: "darkPurple",
                      color: "white",
                    },
                  }}
                >
                  {label}
                </Pill>
              </li>
            ))
        : null}
    </ul>
  </Container>
)

export default PillList
