/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Themed } from "@theme-ui/mdx"

// Utils
import slugify from "slugify"
import queryString from "query-string"
import scrollIntoView from "scroll-into-view"
import theme, { rem } from "@src/theme"
import * as _ from "lodash"
import { isBrowser } from "@helpers"

// Components
import PillList from "@components/PillList"
import Pagination from "@components/Pagination"
import Container from "@components/Container"
import { Grid } from "@theme-ui/components"
import Section from "@components/Section"
import ArticleCard from "@components/ArticleCard"
import ProductCard from "@shop/components/ProductCard"
import ResourceCard from "@components/ResourceCard"
import BannerBlock from "@components/BannerBlock"
import Dropdown from "@components/form/Dropdown"
import Spacers from "@components/Spacers"
import { navigate } from "gatsby"

const ITEMS_PER_PAGE = 8

const CardComponents = {
  Articles: props => <ArticleCard {...props} />,
  News: props => <ArticleCard {...props} linkPrefix="news" />,
  Products: props => <ProductCard {...props} />,
  Resources: props => <ResourceCard {...props} />,
}

const CardResults = ({
  items = [],
  heading = "Goodies",
  TAG_OPTIONS = [],
  type = "Articles",
  maxItems = ITEMS_PER_PAGE,
  DROPDOWN_OPTIONS = null,
  dropdownValue = null,
  handleDropdownChange = null,
  linkPrefix = undefined,
  ...props
}) => {
  const [itemsData, setItemsData] = useState(null)
  const [itemsSearchResult, setItemsSearchResult] = useState(null)
  const [filters, setFilters] = useState(null)
  const [pageNumber, setPageNumber] = useState(0)
  const [totalItemsCount, setTotalItemsCount] = useState(items.length)
  const [showPagination, setShowPagination] = useState(false)

  // useEffect(() => {
  //   if (TAG_OPTIONS.length && props.location) {
  //     const search = queryString.parse(decodeURI(props.location.search))
  //     let tag = search.tag
  //     if (search && tag) {
  //       // hard fix for now
  //       if (tag === "family   friends") {
  //         tag = "family + friends"
  //       }
  //       if (tag === "badges pins") {
  //         tag = "badges + pins"
  //       }

  //       if (
  //         TAG_OPTIONS.filter(
  //           ({ label }) => label.toLowerCase() === tag.toLowerCase()
  //         ).length
  //       ) {
  //         setFilters([tag.toLowerCase()])
  //       }
  //       // small timeout otherwise it wont jump after being navigated to from a different page
  //       setTimeout(() => {
  //         document.getElementById("card-results-list").scrollIntoView()
  //       }, 50)
  //     }
  //   }
  // }, [props.location ? props.location.search : null])

  useEffect(() => {
    setItemsData(items)
    if (items.length > maxItems) {
      setShowPagination(true)
    }
    setItemsSearchResult(items.slice(0, maxItems))
    setPageNumber(0)
  }, [items])

  useEffect(() => {
    if (filters) {
      // if user has deselected all tags, reset everything
      if (!filters.length) {
        setItemsSearchResult(itemsData.slice(0, maxItems))
        setTotalItemsCount(itemsData.length)
        setPageNumber(0)
        setShowPagination(true)
        return
      }
      filterResults()
    }
  }, [filters])

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const tags = params.get("tags")
    if (tags && typeof tags === "string") {
      const tagArr = tags.split(",")
      const filteredArray = tagArr.filter(urlTag =>
        TAG_OPTIONS.map(({ label }) =>
          label.toLowerCase().replaceAll("+", " ")
        ).includes(urlTag.toLowerCase())
      )
      if (filteredArray.length) {
        setFilters(filteredArray.map(filter => filter.replaceAll("   ", " + ")))
      }
    }
  }, [props.location.search, TAG_OPTIONS])

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const tags = params.get("tags")
    const evalTags = filters
      ? tags !== filters.join(",").replaceAll("+", " ")
      : false
    if (filters) {
      if (!filters.length) {
        navigate(`${props.location.pathname}`, { replace: true })
        return
      }

      if (filters && filters.length && evalTags) {
        navigate(`${props.location.pathname}?tags=${filters.join(",")}`, {
          replace: true,
        })
      }
    }
  }, [filters, props.location.pathname, props.location.search])

  const getFilteredItems = () => {
    return itemsData.filter(item => {
      let data = item.node ? item.node : item

      const similarValues = _.intersection(
        data.tags
          ? data.tags.map(data => slugify(data.toLowerCase()))
          : undefined || data.frontmatter.tags
          ? data.frontmatter.tags.map(data => slugify(data.toLowerCase()))
          : [],
        filters.map(filter => slugify(filter.toLowerCase()))
      )
      return similarValues && similarValues.length
    })
  }

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected)
    const offset = Math.ceil(selected * maxItems)
    if (filters && filters.length) {
      const filteredItems = getFilteredItems()
      setItemsSearchResult(filteredItems.slice(offset).slice(0, maxItems))
    } else {
      setItemsSearchResult(itemsData.slice(offset).slice(0, maxItems))
    }
    if (isBrowser) {
      const element = document.getElementById("card-results-list")
      if (element) {
        scrollIntoView(element)
      }
    }
  }

  const filterResults = () => {
    const filteredItems = getFilteredItems()
    setShowPagination(filteredItems.length > maxItems)
    setTotalItemsCount(filteredItems.length)
    setItemsSearchResult(filteredItems.slice(0, maxItems))
    // we want to reset page every time filter has changed, otherwise things can get messy...
    setPageNumber(0)
  }

  const handleFilterClick = tag => {
    if (!filters) {
      setFilters([tag])
      return
    }
    if (filters.indexOf(tag) !== -1) {
      setFilters(
        filters.filter(filter => filter.toLowerCase() !== tag.toLowerCase())
      )
    } else {
      setFilters([...filters, tag])
    }
  }

  return (
    <Section
      sx={{
        minHeight: rem(800),
      }}
      id="card-results-list"
    >
      {DROPDOWN_OPTIONS ? (
        <div>
          <div
            sx={{ display: ["none", "none", "flex"], justifyContent: "center" }}
          >
            {DROPDOWN_OPTIONS.map(option => {
              const selected = dropdownValue === option.value
              return (
                <button
                  onClick={() => handleDropdownChange(option.value)}
                  disabled={selected}
                  sx={{
                    m: 0,
                    px: rem(24),
                    cursor: selected ? "initial" : "pointer",
                    color: selected
                      ? theme.colors.darkPurple
                      : theme.colors.purple,
                    textDecoration: selected ? "none" : "underline",
                    "&:hover,&:focus": {
                      color: theme.colors.darkPurple,
                    },
                  }}
                >
                  <Themed.h3 sx={{ m: 0 }}>{option.label}</Themed.h3>
                </button>
              )
            })}
          </div>
          <div sx={{ display: [null, null, "none"] }}>
            <Dropdown
              label="Shop"
              value={dropdownValue}
              options={DROPDOWN_OPTIONS}
              onChange={handleDropdownChange}
            />
          </div>
        </div>
      ) : null}
      <Spacers.Small />
      <Container>
        <PillList
          options={TAG_OPTIONS}
          onPillClick={handleFilterClick}
          filters={filters}
        />
      </Container>

      {itemsSearchResult && itemsSearchResult.length ? (
        <Container>
          <Grid
            gap={type === "Products" ? rem(16) : rem(32)}
            columns={[type === "Products" ? 2 : 1, 2, 3, 4]}
          >
            {itemsSearchResult.map(item => {
              return CardComponents[type]({
                key: item.id || item.node.id,
                data: item,
                linkPrefix: linkPrefix,
              })
            })}
          </Grid>
        </Container>
      ) : (
        <Container>
          <p
            sx={{
              mt: 0,
              textAlign: "center",
            }}
          >
            There are no <span>{type.toLowerCase()}</span> to show.
          </p>
        </Container>
      )}
      {itemsSearchResult && itemsSearchResult.length && showPagination ? (
        <Pagination
          pageNumber={pageNumber}
          totalItemsCount={totalItemsCount}
          ITEMS_PER_PAGE={maxItems}
          handlePageClick={handlePageClick}
        />
      ) : null}
    </Section>
  )
}

CardResults.propTypes = {
  items: PropTypes.array,
  heading: PropTypes.string,
  type: PropTypes.string,
  TAG_OPTIONS: PropTypes.array,
}

export default CardResults
